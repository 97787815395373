import { ComponentStyleConfig } from "@chakra-ui/react";
import { ExtendTheme } from "~/foundation/Theme";

export default <Partial<ExtendTheme>>{
	name: "Default",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					color: "inherit",
				}
			}
		}
	}
};

export const darkGreen = <Partial<ExtendTheme>>{
	name: "Dark Green",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					color: "primary.darkGreen"
				}
			}
		}
	}
};

export const darkBlue = <Partial<ExtendTheme>>{
	name: "Dark Blue",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					color: "primary.darkBlue"
				}
			}
		}
	}
};

export const white = <Partial<ExtendTheme>>{
	name: "White",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					color: "primary.white"
				}
			}
		}
	}
};